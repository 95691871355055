import { Box, Heading, Text, Stack, Image } from "@chakra-ui/react"
import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { PageQuery404 } from "../types/pages/page-query-404"

const NotFoundPage: React.FC<PageQuery404> = ({ data }) => {
  return (
    <Layout>
      <Box pb={24} pt={36} px={8}>
        <Seo title="Page not Found" />
        {data.allContentfulSiteImages.edges.map(({ node: siteImage }: any) => (
          <Stack align="center" justify={`center`}>
            <Image
              src={siteImage.image.url}
              alt={`404`}
              boxSize={"350px"}
              objectFit={`cover`}
            />
          </Stack>
        ))}
        <Heading
          as={`h1`}
          mb={10}
          fontWeight="bold"
          fontSize={24}
          textAlign="center"
        >
          誠に申し訳ございませんが
          <Box as={`br`} />
          お探しのページが見つかりませんでした
        </Heading>
        <Text
          textAlign="center"
          lineHeight={{ base: 2.4, lg: 2 }}
          p={{ base: 4, lg: 0 }}
          fontSize={{ base: 14, lg: 18 }}
        >
          お探しのページは一時的にアクセスできない状況にあるか
          <Box as={`br`} />
          移動もしくは削除された可能性があります。
        </Text>
      </Box>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  {
    allContentfulSiteImages(filter: { location: { eq: "404" } }, limit: 1) {
      edges {
        node {
          image {
            url
          }
        }
      }
    }
  }
`
